
import { Box, Container, CssBaseline, Grid, styled, Typography, Button, useTheme, useMediaQuery } from "@mui/material"
import { imageList } from "../Gallery/Gallery"
import GalleryCard from "../Gallery/GalleryCard"
function GalleryPage() {
    return (
        <>
            <Container maxWidth={"xl"} sx={{
                p: 3
            }}>
                <Typography variant="h2">Galerie</Typography>
                <Typography variant="body1" gutterBottom>Galerie našich produktů</Typography>
                <Grid container spacing={2}>
                    {imageList.map((element, index) => (
                        <Grid item xs={12} md={6} lg={3}>
                            <GalleryCard imageUrl={element} key={index}></GalleryCard>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    )
}

export default GalleryPage