import { Footer } from "../components/Footer/Footer"
import GalleryPage from "../components/GalleryPage/GalleryPage"
import Layout from "../components/Layout"


function Gallery() {
  return (
    <Layout>
        <GalleryPage></GalleryPage>
        <Footer></Footer>
    </Layout>
  )
}

export default Gallery