

import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";

function GalleryCard({imageUrl}) {

    return (
        <Card sx={{ width: "auto", height: 400, userSelect : "none" }}>
            <CardMedia
                component="img"
                height="400"
                image={imageUrl}
                alt="Obrázky"
            />
        </Card>
    );
}

export default GalleryCard;