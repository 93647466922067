import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { themeOptions } from '../theme/theme';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const drawerWidth = 240;
const navItems = [
    {
        name: "Domov",
        route: "/"
    },
    {
        name: "Sortiment",
        route: "#sort"
    },
    {
        name: "Galerie",
        route: "#gallery"
    },
    {
        name: "FaQ",
        route: "#faq"
    },
    {
        name: "O mně",
        route: "#aboutme"
    },
    {
        name: "Kontakt",
        route: "#contact"
    },
];


const theme = createTheme(themeOptions)
function Layout(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={theme => ({
            backgroundColor: theme.palette.primary.dark,
            height : "100%"
        })}>
            <img src={"/logo.png"} alt='logo' width={80}></img>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <Link to={item.route}>
                        <ListItem key={item.name}>
                            <ListItemButton>
                                <ListItemText primary={item.name}></ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box >
    );

const container = window !== undefined ? () => window().document.body : undefined;

return (
    <ThemeProvider theme={theme}>
            <Helmet>
                <meta name="description" content="Vyhledávejte a naleznete zde nejlepší nabídku na dorty a dezerty. Mráčkovna vám přinese radost z pečení." />
                <meta name="keywords" content="mráčkovna, recepty, dorty, peceni, dezerty, pečení, cukrářství, mrackovna, cukrarina, na objednavku, s sebou, oslava, party" />
                <meta name="author" content="Aneta Brodská" />
                <meta property="og:title" content="Mráčkovna" />
                <meta property="og:description" content="Vyhledávejte a naleznete zde nejlepší nabídku na dorty a dezerty. Mráčkovna vám přinese radost z pečení." />
                <meta property="og:image" content="/logo.png" />
                <meta property="og:url" content="mrackovna.cz" />
                </Helmet>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" position="static" sx={theme => ({
                backgroundColor: theme.palette.primary.dark
            })}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, borderRadius: "50%" }}
                    >
                        <img src={"/logo.png"} alt='logo' width={80}></img>
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button href={item.route} key={item.name} size='large' sx={{ color: '#fff' }}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
        <Box sx={theme => ({
            backgroundColor: theme.palette.primary.dark
        })}>
            {props.children}
        </Box>
    </ThemeProvider>
);
}

Layout.propTypes = {

    window: PropTypes.func,
};

export default Layout;