import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Grid, FormControl, InputLabel, Input, FormHelperText, FormControlLabel, IconButton, Paper, styled, TextareaAutosize } from '@mui/material';
import emailjs from 'emailjs-com';
import { CheckBox, Facebook, Instagram, Phone, WhatsApp } from '@mui/icons-material';
import { SectionHeader } from '../../theme/theme';


const PaperForContacts = styled(Paper)(({ theme }) => ({
    padding: 3,
    minHeight: "20rem",
    width: "20rem",
    height: "auto",
    boxShadow: "5px 7px 9px -2px rgba(142,122,181,.5), inset 5px 7px 9px -2px rgba(142,122,181,.2)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
    transition: "all .3s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
        transform: "translateY(-3px)",
        boxShadow: "5px 7px 9px -2px rgb(142,122,181,.8), inset 5px 7px 9px -2px rgba(142,122,181,.5)",
        backgroundColor: "rgb(183, 132, 183)"

    },

    "& > *": {
        color: "white"
    }

}));



export default function ContactForm() {

    const sendEmail = (e) => {
        e.preventDefault();

        const serviceID = 'service_a2d0lxn';
        const templateID = 'template_olljsco';
        const userID = 'eRH3W8yfrL5rv9GW_';
        console.log("klikám??")
        emailjs.sendForm(serviceID, templateID, e.target, userID).then(
            (result) => {
                console.log(result.text);
                alert('Message sent successfully!');
            },
            (error) => {
                console.log(error.text);
                alert('Something went wrong, please try again later.');
            }
        );
    };

    return (
        <section id='contact'>
            <Box>
                <Container maxWidth={"xl"} sx={{
                    p: 3, minHeight: '100vh', display: 'flex', alignItems: 'center'
                }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='h2'>Kontakt</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={2} sx={{
                                height: "100%"
                            }}>
                                <Grid item xs={12} md={6} >
                                    <Typography variant="h4" color="white" gutterBottom>Odeslat email</Typography>
                                    <Box component="form" onSubmit={sendEmail} sx={{ mt: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="name"
                                                    variant='filled'
                                                    label="Jméno"
                                                    name='jmeno'
                                                    autoComplete='off'
                                                    fullWidth
                                                />

                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="name"
                                                    label="Přijmení"
                                                    variant='filled'
                                                    name='prijmeni'
                                                    autoComplete='off'
                                                    fullWidth
                                                />


                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="E-mail"
                                                    variant='filled'
                                                    fullWidth
                                                    name='email'
                                                    autoComplete='off'
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id='text'
                                                    label="Zpráva"
                                                    variant='filled'
                                                    fullWidth
                                                    name='zprava'
                                                    autoComplete='off'
                                                    multiline
                                                    minRows={4}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button fullWidth size='large' type='submit' variant="outlined" >
                                                    Odeslat
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Typography variant="h4" gutterBottom>Sociální sítě</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <PaperForContacts>
                                                <Instagram sx={{ fontSize: 120 }}></Instagram>
                                                <Box>
                                                    <Typography variant="caption">Instagram</Typography>
                                                    <Typography fontWeight={600} variant="body1" >Mráčkovna </Typography>
                                                </Box>
                                            </PaperForContacts>
                                        </Grid>
                                        <Grid item xs={12}  sm={12} md={6}>
                                            <PaperForContacts>
                                                <Facebook sx={{ fontSize: 120 }}></Facebook>
                                                <Box>
                                                    <Typography variant="caption" >Facebook</Typography>
                                                    <Typography fontWeight={600} variant="body1">Mráčkovna </Typography>
                                                </Box>
                                            </PaperForContacts>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </section>
    );
}