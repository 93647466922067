import { Box, Button, Container, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import GalleryCard from './GalleryCard';
import { SectionHeader } from '../../theme/theme';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export const imageList = [
    "/IMG-20240303-WA0002.jpg",
    "/IMG-20240303-WA0003.jpg",
    "/IMG-20240303-WA0004.jpg",
    "/IMG-20240303-WA0005.jpg",
    "/IMG-20240303-WA0006.jpg",
    "/IMG-20240303-WA0007.jpg",
    "/IMG-20240303-WA0009.jpg",
    "/IMG-20240303-WA0010.jpg",
    "/IMG-20240303-WA0013.jpg",
    "/IMG-20240303-WA0015.jpg",
    "/IMG-20240303-WA0016.jpg",
];

const text = {
    offscreen: {
        opacity: 0,
        y: 320,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.9
        }
    }
}


function Gallery() {
    return (
        <section id='gallery'>
            <Box component={motion.div} variants={text} whileInView="onscreen" initial="offscreen" viewport={{ once: true }} mb={10}>
                <Container maxWidth={"xl"} sx={{

                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Galerie</Typography>
                            <Typography variant="body1" gutterBottom>Galerie našich produktů</Typography>
                        </Grid>
                        <Grid item container>
                            <Grid item xs={12} display={"flex"} justifyContent={"space-between"} >
                                <Link to={"/galerie"}>
                                    <Button variant="outlined">
                                        Zobrazit vše
                                    </Button>
                                </Link>
                                <Box>
                                    <Button variant="outlined" className='button-left' >
                                        <ChevronLeft></ChevronLeft>
                                    </Button>
                                    <Button variant="outlined" className='button-right' >
                                        <ChevronRight></ChevronRight>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Swiper breakpoints={{
                                '@0.00': {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                '@0.75': {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                '@1.00': {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                '@1.50': {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                            }} navigation={{
                                prevEl: '.button-left',
                                nextEl: '.button-right',
                            }} rewind={true} pagination={{
                                dynamicBullets: true,
                                clickable: true
                            }} cubeEffect={2} centerInsufficientSlides modules={[Navigation, Pagination]}>
                                <Box p={3}>
                                    {imageList.map(element => (
                                        <SwiperSlide ><GalleryCard imageUrl={element}></GalleryCard></SwiperSlide>
                                    ))}
                                </Box>

                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </section>
    )
}

export default Gallery