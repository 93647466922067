import { Box, styled, Typography, Button, Grid, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { motion } from "framer-motion";


const HeaderContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
    height: "100vh",
    backgroundImage: "url(/header.jpg)",
    backgroundAttachment: "fixed, scroll",
    backgroundSize: "cover",
    WebkitBackgroundSize: "cover",
    backgroundPosition: "center",
    WebkitBackgroundPosition : "cover",
    [theme.breakpoints.down("md")]: {
        padding: "1rem",
        backgroundAttachment: "scroll"
    }
}));




const text = {
    offscreen: {
        opacity: 0,
        x: 320,
        overflow: "hidden",
    },
    onscreen: {
        x: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
}

function Header() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <HeaderContainer>
                <Box sx={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    zIndex: 0,
                }}></Box>
                <motion.div style={{
                    zIndex: 99
                }} variants={text} animate="onscreen" initial="offscreen">
                    <Typography sx={{
                        fontWeight: 500
                    }} variant="h3" gutterBottom>Peču pro radost a s láskou</Typography>
                    <Typography variant="h1" mb={-1} gutterBottom>Mráčkovna</Typography>
                    <Typography variant="h3" sx={{
                        fontWeight: 200
                    }}>Upeču dle vašich představ nebo Vám pomůžu s náměty</Typography>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        href="#contact"
                        sx={{
                            mt: isMobile ? 2 : 3,
                        }}
                    >
                        Objednat si
                    </Button>
                </motion.div>
            </HeaderContainer>
        </>
    )
}

export default Header