import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Skeleton, Typography } from "@mui/material";
import { Facebook, Instagram } from "@mui/icons-material";
import { Link } from "react-router-dom";


function Copyright(props) {
    return (
      <Typography variant="caption" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" style={{
            textDecoration : "none",
            
        }} href="#">
          Mráčkovna
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
export const Footer = () => {
    return (
        <Box sx={{
            backgroundColor: "rgba(30, 159, 200, 1)"
        }}>
            <Container sx={{ py: 5}} disableGutters>
                <Grid container>
                    <Grid item xs={12} sx={{
                        display : "flex",
                        justifyContent : "center",
                        alignItems : "center",
                        alignContent : "center"
                    }}>
                        <Instagram sx={{fontSize : 40}}></Instagram>
                        <Facebook sx={{fontSize : 40}}></Facebook>
                    </Grid>
                    <Grid item xs={12} sx={{
                        display : "flex",
                        justifyContent : "center",
                        alignItems : "center",
                        alignContent : "center"
                    }}>
                    <Copyright sx={{ pt: 4 }} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};