

import { Favorite } from '@mui/icons-material';
import { Box, Button, Container, CssBaseline, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion';
const HeaderText = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: 1.30,
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
    },
    zIndex: 1
}));

const SubtitleText = styled(Typography)(({ theme }) => ({
    fontWeight: 300,
    color: theme.palette.common.white,
    fontSize: "1.6rem",
    marginTop: ".8em",
    lineHeight: 1.4,
    [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
    },
    zIndex: 1
}));


const text = {
    offscreen: {
        opacity: 0,
        y: 100,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
}

const img = {
    offscreen: {
        opacity: 0,
        y: -100,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            delay: .20,
            stiffness: 100,
            duration: 0.5
        }
    }
}
function AboutMe() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    console.log(isMobile)
    return (
        <section id='aboutme'>
            <Box>
                <Container maxWidth={"xl"} sx={{
                    p: isMobile ? 5 : 0,
                    mt: 5
                }}>

                    <Grid container justifyContent={"space-between"}>
                        <Grid component={motion.div} viewport={{ once: true, amount: 0.8 }} variants={img} initial="offscreen" whileInView="onscreen" item md={6} >
                            <img src={"/aboutme.jpg"} alt="About Me" style={{ borderRadius: "15px", height: isMobile ? "26rem" : "40rem", boxShadow: "10px 10px 16px -4px rgba(0,0,0,0.42" }} />
                        </Grid>
                        <Grid component={motion.div} viewport={{ once: true, amount: 0.8 }} variants={text} initial="offscreen" whileInView="onscreen" item md={6}>
                            <Typography variant="h2">
                                O mně
                            </Typography>
                            <Typography variant='body1' gutterBottom sx={{
                                opacity: 0.8
                            }} mb={2}>Vše co o mně potřebujete vědět</Typography>
                            <Typography variant="body2" paragraph gutterBottom>
                                Jmenuji se Aneta a pečení se věnuji od malička. Ze začátku jsem vypomáhala babičce a mamince v kuchyni a stále jsem chtěla být pečení blíže.
                            </Typography>
                            <Typography variant="body2" paragraph gutterBottom >
                                Nejvíce mě okouzlila cukrařina, které jsem se začala plně věnovat. Pečení je pro mě relaxací a snažím se v něm stále zlepšovat. Hledám nové postupy, chutě a vymýšlím dezerty, které by mohly zaujmout nejen vzhledem, ale hlavně chutí.
                            </Typography>
                            <Typography variant="body2" paragraph gutterBottom>
                                Mráčkovnu jsem založila proto, že je to pro mě malý krok k tomu, abych si jednoho dne mohla otevřít svou vlastní kavárnu. <Favorite sx={{ color: "cyan" }}></Favorite>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </section>
    )
}

export default AboutMe