import { Typography, styled } from "@mui/material";

export const themeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#0096FB',
            contrastText: '#fff',
            dark: '#002D4E',
            light: '#0096FB',
        },
        secondary: {
            main: '#6883BA',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Poppins, sans-serif',
        h1 : {
            letterSpacing : "-.04em",
            lineHeight : 1.16,
            color : "white",
            fontWeight : 600,
            '@media (max-width:600px)': {
                fontSize: '3.5rem',
              },
        },
        h2 : {
            letterSpacing : "-.032em",
            lineHeight : 1.24,
            color : "white",
            fontWeight : 700,
            
        },
        h3 : {
            lineHeight : 1.26,
            letterSpacing : "-.032em",
            color : "white",
            fontWeight : 600,
            '@media (max-width:600px)': {
                fontSize: '2.5rem',
            },
        },
        h4 : {
            lineHeight : 1.36,
            letterSpacing : "-.024em",
            color : "white",
            fontWeight : 600
        },

        body1 : {
            letterSpacing : "-.014em",
            lineHeight : 1.5,
            fontSize : "1.4rem",
            fontWeight : 500,
            color : "#fff",

        },
        body2 : {
            letterSpacing : "-.012em",
            lineHeight : 1.5,
            fontSize : "1.4rem",
            color : "#fff",
            opacity : 0.9

        }
    },
};


export const SectionHeader = styled(Typography)(({ theme }) => ({
    textAlign : "center",
    letterSpacing: 1.30,
    fontWeight : 600,
    color: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
        fontSize: "2rem",
    },
    zIndex : 1
}));