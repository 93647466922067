import { Box, Container, Grid, Paper, styled, Typography } from "@mui/material"
import { motion } from "framer-motion";
import { SectionHeader } from "../../theme/theme";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const StyledCard = styled(Paper)(({ theme }) => ({
    padding: 3,
    minHeight: "20rem",
    height: "100%",
    boxShadow: "5px 7px 9px -2px rgba(142,122,181,.5), inset 5px 7px 9px -2px rgba(142,122,181,.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    transition: "all .3s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
        transform: "translateY(-3px)",
        boxShadow: "5px 7px 9px -2px rgb(142,122,181,.8), inset 5px 7px 9px -2px rgba(142,122,181,.5)",
        backgroundColor: theme.palette.secondary.main

    },

    "& > *": {
        color: "white"
    }

}));


export const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.2,
            staggerChildren: 0.1
        }
    }
};

export const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};



function Faq() {
    return (
        <section id="faq">
                    <Box>
            <Container maxWidth={"xl"} sx={{
                p: 3, display: 'flex', alignItems: 'center'
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h2">Nejčastější dotazy</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <StyledCard sx={{
                            p: 3
                        }}>
                            <Box sx={{
                                height: "8rem"
                            }}>
                                <AccessTimeIcon sx={{ fontSize: 60 }}></AccessTimeIcon>
                            </Box>
                            <Box>
                                <Typography variant="h4">
                                    Jak dlouho trvá výroba dortu?
                                </Typography>
                                <Typography variant="body2">
                                    Výroba dortu trvá průměrně 2 dny.
                                </Typography>
                            </Box>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <StyledCard sx={{
                            p: 3
                        }}>
                            <Box sx={{
                                height: "8rem"
                            }}>
                                <DateRangeIcon sx={{ fontSize: 60 }}></DateRangeIcon>
                            </Box>
                            <Box>
                                <Typography variant="h4">
                                    Kdy je vhodné dort objednat?
                                </Typography>
                                <Typography variant="body2">
                                    Minimálně týden před danou událostí
                                </Typography>
                            </Box>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <StyledCard sx={{
                            p: 3
                        }} >
                            <Box sx={{
                                height: "8rem"
                            }}>
                                <QuestionMarkIcon sx={{ fontSize: 60 }}></QuestionMarkIcon>
                            </Box>
                            <Box>
                                <Typography variant="h4" >
                                    Co vše si můžu objednat
                                </Typography>
                                <Typography variant="body2">
                                    Můžete si objednat z naší nabídky, nebo zrealizovat Váš nápad. 
                                </Typography>
                            </Box>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <StyledCard sx={{
                            p: 3
                        }}>
                            <Box sx={{
                                height: "8rem"
                            }}>
                                <LocationOnIcon sx={{ fontSize: 60 }}></LocationOnIcon>
                            </Box>
                            <Box>
                                <Typography variant="h4" >
                                    Kde si můžu dort vyzvednout?
                                </Typography>
                                <Typography variant="body2" >
                                    Vyzvednutí je vždy na předem domluveném místě.
                                </Typography>
                            </Box>
                        </StyledCard>
                    </Grid>
                </Grid>
            </Container>
        </Box>
        </section>
    )
}

export default Faq