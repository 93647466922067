import { BrowserRouter, Route, Routes } from "react-router-dom"
import MainPage from "../MainPage"
import Gallery from "../Gallery"


function AppRoutes() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<MainPage></MainPage>}/>
            <Route path="/galerie" element={<Gallery></Gallery>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes