import { useEffect } from "react"
import ContactForm from "../components/Contact/Contact"
import Gallery from "../components/Gallery/Gallery"
import Header from "../components/Header/Header"
import Layout from "../components/Layout"
import Sortiment from "../components/Sortiment/Sortiment"
import { Footer } from "../components/Footer/Footer"
import Faq from "../components/Faq/Faq"
import AboutMe from "../components/AboutMe/AboutMe"



function MainPage() {
    return (
        <Layout>
            <Header tabIndex="-1"></Header>
            <Sortiment></Sortiment>
            <Gallery></Gallery>
            <AboutMe></AboutMe>

            <Faq></Faq>
            <ContactForm></ContactForm>
            <Footer></Footer>

        </Layout>
    )
}

export default MainPage