import logo from './logo.svg';
import './App.css';
import AppRoutes from './pages/AppRoutes/AppRoutes';

function App() {
  return (
    <AppRoutes></AppRoutes>
  );
}

export default App;
