import { Box, Container, CssBaseline, Grid, styled, Typography, Button, useTheme, useMediaQuery } from "@mui/material"
import { motion } from "framer-motion";

const images = [
    {
        name: "Narozeninové",
        url: "url(/narozeniny.jpg)"
    },
    {
        name: "Svatební",
        url: "url(/svatebni.jpg)"
    },
    {
        name: "Dětské",
        url: "url(/detske.jpg)"
    },
    {
        name: "Zákusky",
        url: "url(/zakusky.jpg)"
    },
]


const card = {
    offscreen: {
        opacity: 0,
        y: 320,
    },
    onscreen: {
        y: 50,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
}

const text = {
    offscreen: {
        opacity: 0,
    },
    onscreen: {
        x: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
}


function Sortiment() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <section id="sort">
            <Box>
                <Container maxWidth={"xl"} sx={{
                    p : isMobile ? 5 :  0,
                    mt : 5,
                    mb : 5,
                }}>
                    <Grid container spacing={4} sx={{
                        alignItems: "center"
                    }}>
                        <Grid component={motion.div}
                            whileInView="onscreen"
                            initial="offscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            item xs={12} md={6}>
                            <Box component={motion.div} variants={text}>
                                <Typography variant="h2" >Sortiment</Typography>
                                <Typography variant="body1" gutterBottom mb={2} sx={{
                                    opacity: 0.8
                                }}>Moje nabídka</Typography>
                                <Typography fontSize={24} variant="body2" paragraph >
                                Skládá se převážně z dortů, včetně narozeninových, dětských a svatebních. Dorty si můžete vybrat sami a poté probrat vaše představy se mnou; jsem otevřená všem vašim nápadům. Pokud si s výběrem nejste jisti, ráda vám dort navrhnu.
                                </Typography>
                                <Typography fontSize={24} variant="body2" paragraph>Kromě dortů nabízím i různé mini zákusky, jako jsou věnečky, tartaletky, cupcakes a cake pops.</Typography>
                                <Button variant="outlined" size="large" fullWidth sx={{
                                    mt: 3
                                }}>
                                    Objednám si!
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                {images.map(element => (
                                    <Grid item xs={12} sm={6} md={6} lg={6} component={motion.div}
                                        whileInView="onscreen"
                                        initial="offscreen"
                                        viewport={{ once: true, amount: 0.8 }}>

                                        <Box component={motion.div} variants={card} sx={{
                                            width: "auto",
                                            height: "20rem",
                                            flexDirection: "column",
                                            boxShadow: "21px 15px 15px -3px rgba(0,0,0,0.1)",
                                            backgroundImage: element.url,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            "&:hover > *" : {
                                                opacity : 0
                                            }
                                        }}>
                                            <Box sx={{
                                                backgroundColor: "rgba(0,0,0,.5)",
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                transition: "all .3s ease-in-out",
                                            }}>
                                                <Typography variant="h4">{element.name}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box >
        </section>
    )
}

export default Sortiment